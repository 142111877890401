/* SCSS RGB */
$dark-goldenrod: rgba(157, 132, 32, 1);
$sienna: rgba(148, 41, 17, 1);
$cambridge-blue: rgba(119, 172, 162, 1);
$rose-ebony: rgba(89, 56, 55, 1);
$parchment: rgba(244, 233, 205, 1);

/* SCSS Gradient */
$gradient-top: linear-gradient(0deg, #9d8420ff, #942911ff, #77aca2ff, #593837ff, #f4e9cdff);
$gradient-right: linear-gradient(90deg, #9d8420ff, #942911ff, #77aca2ff, #593837ff, #f4e9cdff);
$gradient-bottom: linear-gradient(180deg, #9d8420ff, #942911ff, #77aca2ff, #593837ff, #f4e9cdff);
$gradient-left: linear-gradient(270deg, #9d8420ff, #942911ff, #77aca2ff, #593837ff, #f4e9cdff);
$gradient-top-right: linear-gradient(45deg, #9d8420ff, #942911ff, #77aca2ff, #593837ff, #f4e9cdff);
$gradient-bottom-right: linear-gradient(
  135deg,
  #9d8420ff,
  #942911ff,
  #77aca2ff,
  #593837ff,
  #f4e9cdff
);
$gradient-top-left: linear-gradient(225deg, #9d8420ff, #942911ff, #77aca2ff, #593837ff, #f4e9cdff);
$gradient-bottom-left: linear-gradient(
  315deg,
  #9d8420ff,
  #942911ff,
  #77aca2ff,
  #593837ff,
  #f4e9cdff
);
$gradient-radial: radial-gradient(#9d8420ff, #942911ff, #77aca2ff, #593837ff, #f4e9cdff);

$light-sky-blue: #87cefa;
$light-gray: #dfdfdf;

.messages-container,
.message-input {
  width: 80%;
  overflow: auto;
  max-width: 960px;
}

.message {
  max-width: 70%;
  margin: 10px;
  padding: 10px;
  border-radius: 20px;
  clear: both;
}

.message.user {
  float: right;
  background-color: white; /* you can change the color as per your preference */
}

.message:not(.user) {
  float: left;
  background-color: $light-gray;
}

// .message-content {
//   overflow-wrap: break-word; /* Optional: Break words that would be too long */
// }

.message-input {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .message-input-field {
    flex-grow: 1;
    margin-right: 10px;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
  }
}

.message-submit-button {
  padding: 10px 20px;
  background-color: $light-sky-blue;
  border: none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;

  &:hover {
    background-color: darken($light-sky-blue, 10%);
  }
}
