.wf-force-outline-none[tabindex='-1']:focus {
  outline: none;
}
.terms-pag {
  -moz-font-feature-settings: 'liga' on;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-feature-settings: 'liga' on;
  text-rendering: optimizeLegibility;
}
