.partner-holder {
  .logo-container {
    width: 100%; // takes full width of its parent
    position: relative; // to allow the inner img to be positioned absolutely
    padding-bottom: 100%; // to maintain the aspect ratio of 1:1 (a square)
    // border: 2px solid #000;
    overflow: hidden;
  }

  $image-proportion: 90%;

  .logo-border {
    position: absolute;
    top: 50%;
    left: 50%;
    width: $image-proportion;
    height: $image-proportion;
    object-fit: contain; // to make sure the image scales properly
    transform: translate(-50%, -50%); // to perfectly center the image
  }
}

.row-eq-height {
  display: flex;
  flex-wrap: wrap;
}

.partner-holder {
  display: flex;
  flex-direction: column;
}

.partner-holder-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}