.profile-v2 {
  $social-pushup: 70px;

  font-family: 'Inter', sans-serif;
  min-height: 90vh;
  padding-top: $social-pushup;
  padding-bottom: 2rem;

  .profile-team-logo-container {
    min-width: 30%;
    text-align: center;
  }

  img.profile-team-logo {
    max-height: 200px;
    max-width: 90%;
  }

  .social-icon-column {
    border-left: 1px solid transparent;
    transition: all 0.2s ease-in-out;
  }

  .selected-social-icon-column {
    border-left: 3px solid var(--bs-body-color);
    transform: translateX(3px);
  }

  hr {
    margin: 1.5em;
    border-top: 2px solid black;
    opacity: 0.5;
  }

  .uppercase {
    text-transform: uppercase;
  }

  .talent-name {
    font-size: 2.5em;
    line-height: 1em;
  }

  $description-padding: 10%;

  .talent-description-text {
    padding-left: $description-padding;
    padding-right: $description-padding;
  }

  .horizontal-flanked-text {
    overflow: hidden;
    text-align: center;
  }

  .post-category-select {
    transition: color 0.2s ease;
  }

  .post-category-select:hover {
    color: get-color(lighter-purple);
  }

  .horizontal-flanked-text:before,
  .horizontal-flanked-text:after {
    background-color: #000;
    content: '';
    display: inline-block;
    height: 1px;
    position: relative;
    vertical-align: middle;
    width: 50%;
  }

  .horizontal-flanked-text:before {
    right: 0.5em;
    margin-left: -50%;
  }

  .horizontal-flanked-text:after {
    left: 0.5em;
    margin-right: -50%;
  }

  .line {
    display: inline-block;
    width: 100%;
    border-top: 0.2px solid black;
  }

  .headline {
    font-family: 'Barlow Condensed', sans-serif;
    font-weight: 500;
    text-transform: uppercase;
  }

  .text-bold {
    font-weight: 500;
  }
  --rounded-box-border-radius: 2.4rem;

  .very-round-box {
    border-radius: var(--rounded-box-border-radius);
    height: 100%;
  }

  .social-block-column {
    @include media-breakpoint-up(md) {
      margin-top: -$social-pushup;
    }
  }

  .social-box {
    border-radius: 2.5rem;

    .card-header {
      border-top-left-radius: var(--rounded-box-border-radius);
      border-top-right-radius: var(--rounded-box-border-radius);
    }

    .social-box-content {
      border-bottom-left-radius: var(--rounded-box-border-radius);
      border-bottom-right-radius: var(--rounded-box-border-radius);
    }

    .headline {
      font-size: 2.5em;
    }
    @include media-breakpoint-up(md) {
      .headline {
        font-size: 2.5em;
      }
    }

    .follower-count {
      font-size: 2.2em;
      letter-spacing: -0.04em;

      @include media-breakpoint-up(md) {
        font-size: 2.2em;
      }
    }

    .social-icon {
      padding: 10px;
      border-radius: 46%;
      color: white;
      font-size: 4em;

      @include media-breakpoint-up(md) {
        font-size: 4em;
      }

      &:hover {
        transform: scale(1.15);
      }

      transition: all 0.2s ease;
    }
  }

  .stats-box {
    .headline {
      font-size: 2.1em;
    }

    .rank-digit {
      padding: 0;
      width: 1rem;
    }

    .country-rank,
    .fancy-rank {
      font-family: 'Barlow Condensed', sans-serif;
      font-weight: 500;
      font-size: 2em;
      // letter-spacing: -0.04em;
    }

    .country-name,
    .list-name {
      font-weight: 500;
      padding-top: 0.5em;
      font-size: 1.2em;
    }

    .interests {
      font-size: 1.1em;
    }
  }

  .posts-box {
    .headline {
      text-align: center;
      font-size: 1.4em;
    }

    @media screen and (min-width: 768px) {
      .headline {
        font-size: 2.4em;
      }
    }

    .category-headline {
      transition: all 0.2s ease;
    }

    .category-selected {
      font-weight: 600;
    }

    .category-headline {
      &:hover {
        color: get-color(lighter-purple);
        // transform: scale(1.05);
      }
    }

    .post-card {
      height: 360px;
      position: relative;

      .image-holder {
        position: absolute;
        display: block;
        width: 100%;
        height: auto;
        height: 360px;
        background-position: center center;
        background-size: cover;
      }

      img.squarify {
        width: 100%; /* Set the width of the image to 100% */
        height: 100%; /* Set the height of the image to 100% */
        object-fit: cover; /* This will stretch the image to fit the container, while maintaining its aspect ratio */
        object-position: center; /* This will center the image within the container */
      }
    }
  }
}
