.login {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;

  & > .row {
    height: 100vh;
    display: flex;
  }

  .welcome-box {
    padding: 60px;
  }
}

.login-container {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bg-blue-gradient {
  background: linear-gradient(
    -135deg,
    scale-color(get-color(tpp-blue), $lightness: 40%),
    get-color(tpp-blue)
  );
}

.signin-main {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
}

.shared-prelogin {
  .signin-main {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
  }

  .logo-container {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .company-logo {
    max-height: 100px;
    max-width: 200px;
    object-fit: contain;
  }
}

.login {
  .logo-container {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .company-logo {
    max-height: 220px;
    max-width: 260px;
    object-fit: contain;
  }
}

.error {
  color: red;
  text-align: center;
  margin-top: 8px;
}

.sign-in-btn {
  background-color: get-color(gray-800);
  border: none;
  border-radius: 40px;
  color: #fff;
  cursor: pointer;
  font-size: 20px;
  height: 65px;
  width: 140px;

  &:hover {
    background-color: get-color(gray-700);
    color: #fff;
  }
}
